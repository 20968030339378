import React from "react"
import { Link } from 'gatsby'
import { ArrowRight } from 'react-feather'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const NewsLetterCard = ({ link, image, title = '', desc1 = '', desc2 = '', width, ...props }: any) => {
    const { t } = useTranslation()

    return (
        <div className={width==='50%' ?
            "w-full max-w-[484px] md:min-h-[350px] flex flex-col bg-white shadow-lg rounded-md overflow-hidden hover:shadow-2xl drop-shadow-lg transition ease-in-out delay-50 bg-blue-500 hover:-translate-y-1 hover:scale-110"
            : "w-full max-w-[1050px] md:min-h-[350px] flex flex-col md:flex-col lg:flex-row xl:flex-row bg-white shadow-lg rounded-md overflow-hidden hover:shadow-2xl drop-shadow-lg transition ease-in-out delay-50 bg-blue-500 hover:-translate-y-1 hover:scale-110"
            } { ...props }>
            <Link to={ link } className="rounded-lg p-6 align-middle m-auto">
                <img src={ image } className={ width==='50%' ?
                    "shrink-0 shadow-lg object-contain max-h-[260px] h-[250px] rounded-lg py-auto px-auto"
                    : "shrink-0 shadow-lg object-contain max-h-[260px] h-[250px] md:min-w-[466px] md:max-w-[466px] rounded-lg py-auto"
                } alt={ title } />
            </Link>

            <div className="flex flex-col gap-7 p-6 grow my-auto align-middle">

                <div className='flex flex-col gap-2 grow'>
                    <Link to={ link }>
                        <p className="text-2xl font-semibold text-neutral-700">{ title }</p>
                    </Link>
                    <p className="text-neutral-500 text-ellipsis">{ desc1 }</p>
                    <p className="text-neutral-500 text-ellipsis">{ desc2 }</p>
                </div>

                <div className="flex justify-between select-none">
                    <Link to={ link } className="flex font-semibold text-theme-blue items-center gap-2">
                        <span>
                            { t('common.cta.read-more') }
                        </span>
                        <ArrowRight size={ 16 } strokeWidth={ 3 } />
                    </Link>
                </div>
            </div>
        </div>
    )
}