import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

const MonthlyNewsLetterPage = ({ month }: any) => {
  const { t } = useTranslation()

  return (
    <div>
        <section className="relative flex md:flex-row sm:justify-between sm:flex-col flex-col items-center lg:text-left w-full lg:mx-auto mb-10">
          <div className='min-w-[60%] flex flex-col gap-4'>
              <h4 className="text-xl tracking-wider font-light w-48 mb-6 font-medium uppercase sm:text-xl md:text-xl bg-gradient-to-r from-theme-orange from-0% via-theme-orange via-10% to-theme-blue to-100% bg-clip-text text-transparent">
                  {t('newsletter-page.heading')}
              </h4>
              <h2 className="text-3xl tracking-wider font-light sm:text-5xl md:text-5xl font-semibold">
                  {month + ' ' + t('newsletter-page.subPage.heading')}
              </h2>
              <p className="text-2xl sm:text-2xl sm:max-w-xl md:text-2xl min-w-[100%]">
                  {t('newsletter-page.subPage.desc')}
              </p>
          </div>
      </section>
        <div className="h-[1px] sm:block shrink-0 grow bg-gray-200" />
      </div>
  );
}

export default MonthlyNewsLetterPage

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
