import React from "react"
import { Container } from "components/library"
import { graphql } from "gatsby"
import { CtaBlock } from "components/common/cta-block"
import MonthlyNewsLetterPage from "components/pages/newsletter/monthly-main-page"
import { NewsLetterCard } from "components/pages/newsletter/newsletter-card"
import NewsletterImage from "images/newsletter-page/newsletter.jpg"

const NewsletterPage = ({pageContext:{newsletterPage,mnthYr}}:any) => {
  console.log(newsletterPage)

  return (
    <main className="-mt-28 sm:-mt-36">
      <Container className="flex flex-col gap-16 pb-24 pt-48">
      	<MonthlyNewsLetterPage month={`${mnthYr?.split(' ')[0].charAt(0).toUpperCase()}${mnthYr?.split(' ')[0].slice(1)}`}/>
      </Container>
      <Container className="flex flex-row gap-16 pb-24 pt-6 md:px-24 sm:px-2 flex-wrap">
        {newsletterPage.sort((a:any,b:any)=>a.order-b.order).map((pageItem:any) => {
          const currentData = pageItem;
          if (currentData) {
            return (
              <NewsLetterCard
                title={currentData.title}
                image={currentData.image===''? NewsletterImage :currentData.image}
                link={currentData.link}
                linkText={currentData.linkText}
                desc1={currentData.desc1}
                desc2={currentData.desc2}
                width={currentData.width}
              />
            )
          }
          return null;
        })}
      </Container>

      <CtaBlock className="md:mt-[100px] lg:mt-0" />
    </main >
  );
}

export default NewsletterPage

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
